<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Form Texts" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Title"
                  v-model="title"
                  :error="!!errors.title"
                  :error-messages="errors.title"
                  @input="delete errors.title"
                />
                <va-input
                  label="Description"
                  v-model="description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                  @input="delete errors.description"
                />
                <va-input
                  label="Label Name"
                  v-model="labelName"
                />
                <va-input
                  label="Label Phone"
                  v-model="labelPhone"
                />
                <va-input
                  label="Label Mail"
                  v-model="labelMail"
                />
                <va-input
                  label="Label Comments"
                  v-model="labelComments"
                />
                <va-input
                  label="Button Text"
                  v-model="buttonText"
                />
                <va-input
                  type="textarea"
                  label="Success Text"
                  v-model="successText"
                />
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-card
                  title="Send to Emails Array"
                  style="margin-bottom: 25px;"
                >
                  <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  <div v-for="(input, index) in sendToEmails" :key="index">
                    <va-input
                      :label="`Email ${index+1}`"
                      v-model="sendToEmails[index]"
                      style="margin-bottom: 10px;"
                    />
                    <va-button style="margin-bottom: 30px;" v-if="sendToEmails.length > 0" color="danger" small @click="removeInput(index)">Видалити</va-button>
                  </div>
                </va-card>
                <va-input
                  label="Email Subject"
                  v-model="emailSubject"
                />
                <va-button
                  @click="submit()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      title: '',
      description: '',
      labelName: '',
      labelPhone: '',
      labelMail: '',
      labelComments: '',
      buttonText: '',
      successText: '',

      sendToEmails: [],
      emailSubject: '',

      errors: [],

      tabTitles: ['Texts', 'Email configs'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        title: this.title,
        description: this.description,
        labelName: this.labelName,
        labelPhone: this.labelPhone,
        labelMail: this.labelMail,
        labelComments: this.labelComments,
        buttonText: this.buttonText,
        successText: this.successText,
        sendToEmailsArray: this.sendToEmails,
        emailSubject: this.emailSubject,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    addInput () {
      this.sendToEmails.push('')
    },
    removeInput (index) {
      this.sendToEmails.splice(index, 1)
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.formcareer_name || !!this.errors.formcareer_email || !!this.errors.formcareer_phone ||
          !!this.errors.formcareer_career_objective || !!this.errors.formcareer_city || !!this.errors.formcareer_additional_information ||
          !!this.errors.formcareer_without_resume || !!this.errors.formcareer_file || !!this.errors.formcareer_file_message || !!this.errors.formcareer_submit
      } else if (tab === 1) {
        return !!this.errors.formcareer_success_title || !!this.errors.formcareer_success_message
      }
      return false
    },
    submit () {
      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/form-texts?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/form-texts?lang=${this.locale}`)
        .then(response => {
          // console.log(response.data)
          this.title = response.data.title
          this.description = response.data.description
          this.labelName = response.data.labelName
          this.labelMail = response.data.labelMail
          this.labelPhone = response.data.labelPhone
          this.labelComments = response.data.labelComments
          this.buttonText = response.data.buttonText
          this.successText = response.data.successText
          if (response.data.sendToEmails) {
            this.sendToEmails = JSON.parse(response.data.sendToEmails)
          }
          this.emailSubject = response.data.emailSubject
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
